import "@hotwired/turbo-rails"

import "./pwa_install";
import "./custom_chart";
import "./controllers";

import { RRule } from "rrule";
window.RRule = RRule;

import Tagify from '@yaireo/tagify'
window.Tagify = Tagify;

import "chartkick/chart.js";
import Chart from "chart.js/auto"
import ChartDataLabels from "chartjs-plugin-datalabels";
import { SankeyController, Flow } from 'chartjs-chart-sankey';
import { SortedBarStack, CustomTooltip, SortedTooltip, PaddingBelowLegends, DarkModeLabelPlugin, BeautifyPlugin } from "./custom_chart";

Chart.register(BeautifyPlugin, SortedBarStack, CustomTooltip, SortedTooltip, PaddingBelowLegends, DarkModeLabelPlugin, SankeyController, Flow, ChartDataLabels);

window.Chart = Chart;
window.ChartDataLabels = ChartDataLabels;
window.DarkModeLabelPlugin = DarkModeLabelPlugin;

// hide labels when value is 0
Chart.defaults.set('plugins.datalabels', {
  display: function (context) {
    return context.dataset.data[context.dataIndex] !== 0;
  }
});

import AOS from "aos";
window.AOS = AOS;
AOS.init();

import Alpine from 'alpinejs'
window.Alpine = Alpine

var alpineInitialized = false;
document.addEventListener("turbo:load", function () {
  if (!alpineInitialized) {
    alpineInitialized = true
    Alpine.start();
  }
});

document.addEventListener("DOMContentLoaded", function () {
  if (!alpineInitialized) {
    alpineInitialized = true
    Alpine.start();
  }
});
