import '@khmyznikov/pwa-install';

setTimeout(() => {
  var pwaInstall = document.querySelector("pwa-install");
  if (pwaInstall == null) return;

  // if (pwaInstall.isAppleMobilePlatform && $("#signed_in").length === 1) {
  //   pwaInstall.showDialog();
  // }

  document.querySelectorAll(".pwa-install").forEach(function (element) {
    element.addEventListener("click", function () {
      console.log("pwa-install clicked");
      pwaInstall.showDialog();
    });
  });

  if (pwaInstall.isUnderStandaloneMode) {
    document.querySelectorAll(".pwa-install").forEach(function (element) {
      element.classList.add("hidden");
    });
  }
}, 1000)
