import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    // if (this.element.dataset.chart == null) {
    //   if (window.loadCurrentChart != null) {
    //     document.getElementById('chart-loading').style.display = 'none';
    //     window.loadCurrentChart();
    //   }
    // }
  }

  submitForm(e) {
    document.getElementById('current_chart').value = this.element.dataset.chart;
  }
}
