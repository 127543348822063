import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    // replaced by backdrop-filter in CSS
    // $("dialog").css("backdrop-filter", "blur(3px)");
    document.querySelector("html").style.overflow = "hidden";
  }

  // hide modal
  // action: "turbo-dialog#hideModal"
  hideModal() {
    // document.querySelector("main").style.filter = "";
    document.querySelector("#server_dialog").removeAttribute("src");
    // Remove src reference from parent frame element
    // Without this, turbo won't re-open the modal on subsequent click
    document.querySelector("html").style.overflow = "auto";
    let serverDialog = document.querySelector("#server_dialog");
    if (serverDialog) {
      serverDialog.parentNodemoveChild(serverDialog);
    }
  }
  hideTarget() {
    this.element.removeAttribute("src")
    this.element.remove()
    // document.querySelector("main").style.filter = "";
    document.querySelector("html").style.overflow = "auto";
  }

  // hide modal on successful form submission
  // action: "turbo:submit-end->turbo-dialog#submitEnd"
  submitEnd(e) {
    if (e.detail.success) {
      this.hideTarget()
    }
  }

  // hide modal when clicking ESC
  // action: "keyup@window->turbo-dialog#closeWithKeyboard"
  closeWithKeyboard(e) {
    if (e.code == "Escape") {
      this.hideTarget()
    }
  }
}
